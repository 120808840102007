import { mapMutations } from 'vuex'

export const mixin = {
  methods: {
    ...mapMutations({
      clearAge: 'personalDataModule/CLEAR_AGE',
      clearPersonalData: 'personalDataModule/CLEAR_PERSONAL_DATA',
      clearAddress: 'addressModule/CLEAR_ADDRESS',
      clearContact: 'contactModule/CLEAR_CONTACT',
      clearContacts: 'contactModule/CLEAR_CONTACTS',
      clearModalityLocalTraining: 'modalityLocalTrainingModule/CLEAR_MODALITY_LOCALTRAINING',
      clearModalitiesLocals: 'modalityLocalTrainingModule/CLEAR_MODALITIES_LOCALS'
    }),

    clearState() {
      this.clearAge()
      this.clearPersonalData()
      this.clearAddress()
      this.clearContacts()
      this.clearModalityLocalTraining()
      this.clearModalitiesLocals()
    }
  }
}
